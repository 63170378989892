@import "app.scss";
/************************ CONTACT **************************************/
.contact {
  min-height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)), url("/img/coquelicots.jpg");
  background-size: cover;
  text-align: center;
  padding-bottom: 100px;
}

input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus, input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }

textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; }
textarea:focus::-moz-placeholder { color:transparent; }
textarea:focus:-ms-input-placeholder { color:transparent; }

input::-webkit-input-placeholder { color: #adadad;}
input:-moz-placeholder { color: #adadad;}
input::-moz-placeholder { color: #adadad;}
input:-ms-input-placeholder { color: #adadad;}

textarea::-webkit-input-placeholder { color: #adadad;}
textarea:-moz-placeholder { color: #adadad;}
textarea::-moz-placeholder { color: #adadad;}
textarea:-ms-input-placeholder { color: #adadad;}

label {
  display: block;
  margin: 0;
}

button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

.wrap-contact100 {
  margin: auto;
  width: 790px;
  background: #ffffff87;
  border-radius: 2px;
  padding: 55px 95px 55px 95px;
}

.contact100-form {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.wrap-input100 {
  width: 100%;
  position: relative;
  border-radius: 2px;
  margin-bottom: 34px
}

.wrap-input100 ul{
  list-style: none;
  color: #d21313;
  text-align: left;
  padding: 0;
}

.label-input100 {
  font-family: $font-family1;
  font-size: 20px;
  color: #1d1c1c;
  line-height: 1.5;
  text-transform: uppercase;
  width: 100%;
  padding-bottom: 11px;
}

.input100 {
  display: block;
  width: 100%;
  background: #f8f9fa;
  font-family: $font-family2;
  font-size: 18px;
  color: #333333;
  line-height: 1.2;
  padding: 0 25px;
}

input.input100 {
  height: 55px;
}

textarea.input100 {
  min-height: 162px;
  padding-top: 19px;
  padding-bottom: 15px;
}

.focus-input100 {
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid;
  border-color: #d92323;
  border-color: -webkit-linear-gradient(45deg, #d7400a, #d92323);
  border-color: -o-linear-gradient(45deg, #d7400a, #d92323);
  border-color: -moz-linear-gradient(45deg, #d7400a, #d92323);
  border-color: linear-gradient(45deg, #d7400a, #d92323);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transform: scaleX(1.1) scaleY(1.3);
  -moz-transform: scaleX(1.1) scaleY(1.3);
  -ms-transform: scaleX(1.1) scaleY(1.3);
  -o-transform: scaleX(1.1) scaleY(1.3);
  transform: scaleX(1.1) scaleY(1.3);
}

.container-contact100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -4px;
}

.contact100-form-btn {
  font-family: $font-family2;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 150px;
  height: 55px;
  border-radius: 27px;
  background: #d92323;
  background: -webkit-linear-gradient(45deg, #d7400a, #d92323);
  background: -o-linear-gradient(45deg, #d7400a, #d92323);
  background: -moz-linear-gradient(45deg, #d7400a, #d92323);
  background: linear-gradient(45deg, #d7400a, #d92323);
  position: relative;
  z-index: 1;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.contact100-form-btn::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  border-radius: 27px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #333333;
  opacity: 1;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.contact100-form-btn:hover:before {
  opacity: 0;
}

.contact100-form-btn i {
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
.contact100-form-btn:hover i {
  -webkit-transform: translateX(10px);
  -moz-transform: translateX(10px);
  -ms-transform: translateX(10px);
  -o-transform: translateX(10px);
  transform: translateX(10px);
}

@media (max-width: 768px) {
  .wrap-contact100 {
    padding: 55px 45px 68px 45px;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .wrap-contact100 {
    padding: 55px 15px 68px 15px;
    width: 100%;
  }
}
